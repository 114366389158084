import { useEffect, useState } from 'react'
import { useLocale } from '@/providers/locale.provider'
import { DialogClose } from '@radix-ui/react-dialog'
import { useForm } from 'react-hook-form'

import { useUpdateCampaignBudgetMutation } from '@/services/campaigns/mutation.service'

import { CampaignChargeType, CampaignStatusEnum } from '@/enums/campaign.enum'
import { CAMPAIGN_PUBFEED_THRESHOLD_CLICKS } from '@/constants/campaign.constant'

import { getMinimumBudgetAfterAdjustingThresholdClicks } from '@/lib/campaign'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { toast } from '@/components/ui/use-toast'

import MoneyInput from '../../common/form/money-input'
import Loader from '../../common/loader'
import Money from '../../common/money'
import { ActionButtonComponent } from '../actions-button'
import CampaignReactivationNotice from './common/reactivation-notice'

const UpdateCampaignBudgetDialog: React.FC<ActionButtonComponent> = ({
  campaign,
  children,
  preOpen = false,
  onClose = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(preOpen)

  const locale = useLocale()

  const [totalBudget, setTotalBudget] = useState(0)
  const [marketplaceBudget, setMarketplaceBudget] = useState(0)
  const [pubfeedBudget, setPubfeedBudget] = useState(0)
  const [hasMarketplace, setHasMarketplace] = useState(false)
  const [hasPubfeed, setHasPubfeed] = useState(false)

  const allowToSetMaxDeliveredAsBudget =
    campaign.status === CampaignStatusEnum.Finished ||
    campaign?.placements?.pubfeed?.optedOut

  const resolveBudgets = () => {
    const hasMarketplace =
      campaign?.details?.chargeType !== CampaignChargeType.CPM &&
      Number(campaign?.placements?.marketplace?.cpc ?? 0) > 0 &&
      Number(campaign?.placements?.marketplace?.budget ?? 0) > 0

    setHasMarketplace(hasMarketplace)

    const hasPubfeed =
      campaign?.details?.chargeType === CampaignChargeType.CPM ||
      (Number(campaign?.placements?.pubfeed?.cpc ?? 0) > 0 &&
        Number(campaign?.placements?.pubfeed?.budget ?? 0) > 0)

    setHasPubfeed(hasPubfeed)

    const marketplaceBudget = hasMarketplace
      ? parseFloat(campaign?.placements?.marketplace?.budget ?? '0')
      : 0
    const pubfeedBudget = hasPubfeed
      ? parseFloat(campaign?.placements?.pubfeed?.budget ?? '0')
      : 0
    const totalBudget = marketplaceBudget + pubfeedBudget
    setMarketplaceBudget(marketplaceBudget)
    setPubfeedBudget(pubfeedBudget)
    setTotalBudget(totalBudget)
  }

  useEffect(() => {
    resolveBudgets()
  }, [campaign])

  const form = useForm({
    defaultValues: {
      marketplaceBudget: marketplaceBudget,
      pubfeedBudget: pubfeedBudget,
      pubfeedUseMaxDeliveredAsBudget: false,
    },
  })

  useEffect(() => {
    form.reset({
      marketplaceBudget: marketplaceBudget,
      pubfeedBudget: pubfeedBudget,
      pubfeedUseMaxDeliveredAsBudget: false,
    })
  }, [marketplaceBudget, pubfeedBudget])

  const [updatedTotalBudget, setUpdatedTotalBudget] = useState(totalBudget)

  form.watch((d) => {
    setUpdatedTotalBudget(
      Number(d?.marketplaceBudget || 0) +
        (d.pubfeedUseMaxDeliveredAsBudget
          ? Number(campaign?.placements?.pubfeed?.deliveredBudget || 0)
          : Number(d?.pubfeedBudget || 0)),
    )
  })

  const onSuccess = () => {
    toast({
      title: 'Campaign budget updated successfully',
      color: 'success',
      duration: 5000,
    })
    handleOpenChange(false)
  }

  const onError = async (error: any) => {
    try {
      if ((error?.data?.errors ?? []).length) {
        error.data.errors.map((x: any) => {
          if (x.field.includes('marketplace')) {
            form.setError('marketplaceBudget', { message: x.message })
          }
          if (x.field.includes('pubfeed')) {
            form.setError('pubfeedBudget', { message: x.message })
          }
        })
      }
    } catch (e) {
      toast({
        title:
          'Failed to update campaign budget. Please contact tech support if issue persists.',
        color: 'destructive',
      })
      handleOpenChange(false)
    }
  }

  const { mutate: updateCampaignBudgetMutation, isPending } =
    useUpdateCampaignBudgetMutation({
      campaign,
      onSuccess,
      onError,
    })

  const minPubfeedBudget = getMinimumBudgetAfterAdjustingThresholdClicks(
    Number(campaign?.placements?.pubfeed?.budget ?? 0),
    Number(campaign?.placements?.pubfeed?.deliveredBudget ?? 0),
    CAMPAIGN_PUBFEED_THRESHOLD_CLICKS,
    Number(campaign?.placements?.pubfeed?.cpc ?? 0),
  )

  const triggerSubmit = () => {
    const pubfeedUseMaxDeliveredAsBudget = hasPubfeed
      ? form.getValues('pubfeedUseMaxDeliveredAsBudget')
      : false

    const pubfeedBudget = pubfeedUseMaxDeliveredAsBudget
      ? Number(campaign?.placements?.pubfeed?.deliveredBudget)
      : Number(form.getValues('pubfeedBudget'))

    if (hasPubfeed && pubfeedBudget <= 0) {
      form.setError('pubfeedBudget', {
        message: 'Please enter a valid budget',
      })
      return
    }

    if (hasPubfeed && pubfeedBudget < minPubfeedBudget) {
      form.setError('pubfeedBudget', {
        message: `Pubfeed budget must be greater than minimum budget (i.e. Current delivered budget + ${CAMPAIGN_PUBFEED_THRESHOLD_CLICKS} clicks)`,
      })
      return
    }

    updateCampaignBudgetMutation({
      marketplace: hasMarketplace
        ? parseFloat(String(form.getValues('marketplaceBudget'))) || 0
        : undefined,
      pubfeed: hasPubfeed ? pubfeedBudget : undefined,
      pubfeedUseMaxDeliveredAsBudget,
    })
  }

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open)
    if (!open) {
      form.reset()
      onClose()
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="min-w-[625px]">
        <DialogTitle>Update Budgets</DialogTitle>
        <div>
          <CampaignReactivationNotice campaign={campaign} />
          <Form {...form}>
            <form onSubmit={form.handleSubmit(triggerSubmit)}>
              <Table className="w-full">
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-32 px-0">Placements</TableHead>
                    <TableHead className="w-32 px-0">Existing Budget</TableHead>
                    <TableHead className="w-32 px-0">
                      Allocated Budget
                    </TableHead>
                    <TableHead className="w-48 max-w-48 px-0">
                      New Budget
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {hasMarketplace ? (
                    <>
                      <TableRow
                        className={
                          form.formState.errors.marketplaceBudget
                            ? 'border-b-0 pb-0'
                            : ''
                        }>
                        <TableCell className="px-0">Marketplace</TableCell>
                        <TableCell className="px-0">
                          <Money
                            currency={locale?.currency}
                            locale={locale?.locale}>
                            {marketplaceBudget}
                          </Money>
                        </TableCell>
                        <TableCell className="px-0">
                          <Money
                            currency={locale?.currency}
                            locale={locale?.locale}>
                            {campaign?.placements?.marketplace
                              ?.allocatedBudget || 0}
                          </Money>
                        </TableCell>
                        <TableCell className="px-0">
                          <FormField
                            name="marketplaceBudget"
                            rules={{
                              required: true,
                              min: {
                                value: Number(
                                  campaign?.placements?.marketplace
                                    .allocatedBudget || 0,
                                ),
                                message: `Marketplace budget cannot be lower than currently allocated budget.`,
                              },
                            }}
                            render={({ field }) => (
                              <FormItem>
                                <MoneyInput
                                  {...field}
                                  currencySymbol={locale!.currencySymbol}
                                />
                              </FormItem>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                      {form.formState.errors?.marketplaceBudget ? (
                        <TableRow className="border-t-0">
                          <TableCell className="px-0 pt-0" colSpan={4}>
                            <FormMessage className="px-0 pt-0 text-right">
                              {form.formState.errors.marketplaceBudget?.type ==
                              'required' ? (
                                <span>Marketplace budget is required</span>
                              ) : (
                                <span>
                                  {
                                    form.formState.errors.marketplaceBudget
                                      .message
                                  }
                                </span>
                              )}
                            </FormMessage>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  ) : null}
                  {hasPubfeed ? (
                    <>
                      <TableRow className={'border-b-0 pb-0'}>
                        <TableCell className="px-0">Pubfeed</TableCell>
                        <TableCell className="px-0">
                          <Money
                            currency={locale?.currency}
                            locale={locale?.locale}>
                            {pubfeedBudget}
                          </Money>
                        </TableCell>
                        <TableCell className="px-0 ">
                          <Money
                            currency={locale?.currency}
                            locale={locale?.locale}>
                            {campaign?.placements?.pubfeed?.deliveredBudget ??
                              0}
                          </Money>
                          &nbsp;delivered
                        </TableCell>
                        <TableCell className="px-0">
                          <FormField
                            name="pubfeedBudget"
                            disabled={form.getValues(
                              'pubfeedUseMaxDeliveredAsBudget',
                            )}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <FormItem>
                                <MoneyInput
                                  {...field}
                                  currencySymbol={locale!.currencySymbol}
                                />
                              </FormItem>
                            )}
                          />
                        </TableCell>
                      </TableRow>

                      {form.formState.errors.pubfeedBudget ? (
                        <TableRow className="border-0">
                          <TableCell className="p-0" colSpan={4}>
                            <FormMessage className="px-0 pt-0 text-right">
                              {form.formState.errors.pubfeedBudget?.type ==
                              'required' ? (
                                <span>Pubfeed budget is required</span>
                              ) : (
                                <span>
                                  {form.formState.errors.pubfeedBudget.message}
                                </span>
                              )}
                            </FormMessage>
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {allowToSetMaxDeliveredAsBudget && (
                        <TableRow>
                          <TableCell className="px-0 pt-0" colSpan={3} />
                          <TableCell className="px-0 pt-0">
                            <FormField
                              name="pubfeedUseMaxDeliveredAsBudget"
                              render={({ field }) => (
                                <FormItem className="mt-0">
                                  <div className="flex items-center gap-2">
                                    <Checkbox
                                      id="use-delivered-amount"
                                      checked={field.value}
                                      onCheckedChange={field.onChange}
                                      size="sm"
                                      color="default"
                                    />
                                    <label
                                      htmlFor="use-delivered-amount"
                                      className="cursor-pointer text-sm">
                                      Use delivered amount
                                    </label>
                                  </div>
                                </FormItem>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ) : null}
                  {!hasMarketplace && !hasPubfeed ? (
                    <TableRow>
                      <TableCell className="px-0 font-bold" colSpan={3}>
                        No placements found
                      </TableCell>
                    </TableRow>
                  ) : null}
                  <TableRow>
                    <TableCell className="px-0 font-bold">Total</TableCell>
                    <TableCell className="px-0 font-bold">
                      <Money
                        currency={locale?.currency}
                        locale={locale?.locale}>
                        {totalBudget}
                      </Money>
                    </TableCell>
                    <TableCell className="px-0 font-bold">&nbsp;</TableCell>
                    <TableCell className="flex items-center space-x-2 px-0 font-bold">
                      <Money
                        currency={locale?.currency}
                        locale={locale?.locale}>
                        {updatedTotalBudget}
                      </Money>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <DialogFooter>
                <div className="flex w-full justify-end space-x-2 pt-8">
                  <DialogClose asChild>
                    <Button
                      size="sm"
                      variant="raised"
                      color="default"
                      onClick={() => {}}>
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button
                    size="sm"
                    color="action"
                    variant="raised"
                    type="submit"
                    disabled={isPending || (!hasMarketplace && !hasPubfeed)}>
                    <span className={isPending ? 'invisible' : ''}>Update</span>
                    {isPending ? (
                      <Loader size={1} className="absolute min-h-full" />
                    ) : null}
                  </Button>
                </div>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateCampaignBudgetDialog
