import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { useForm } from 'react-hook-form'

import { useUpdateCampaignMutation } from '@/services/campaigns/mutation.service'

import { CampaignUpdateRequest } from '@/interfaces/campaign.interface'
import { CampaignUpdateSchema } from '@/schemas/campaign-update.schema'
import { Campaign } from '@/schemas/campaign.schema'

import { getActiveAffiliatePlatforms } from '@/lib/affiliate'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'

import { ActionButtonComponent } from '../actions-button'

type FormValues = {
  name: string
  brand: string
  referenceUrl: string
  description: string
  useLinkbyUtmTag: boolean
  customUtmTags: boolean
  source: string
  medium: string
  campaignName: string
  content: string
  allowDeeplinking: boolean
  affiliatePlatform: boolean
  affiliatePlatformName: string
  sid: string
}

const EditCampaignDialog: React.FC<ActionButtonComponent> = ({
  campaign,
  children,
  preOpen = false,
  onClose = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(preOpen)

  useEffect(() => {
    setIsOpen(preOpen)
  }, [preOpen])

  const linkbyDefaultsUTM = {
    source: 'linkby',
    medium: 'cpc',
    campaign: '{campaignName}',
    content: '{publisherName}',
  }

  const affiliatePlatforms = getActiveAffiliatePlatforms(campaign)

  const mapAffiliatePlatforms = (platform: string | undefined) => {
    return Object.keys(affiliatePlatforms).find(
      (p: string) => platform && p.toLowerCase() === platform.toLowerCase(),
    )
  }

  const loadDetails = (campaign: Campaign): FormValues => ({
    name: campaign.name || '',
    brand: campaign.brief.brand || '',
    referenceUrl: campaign.brief.url || '',
    description: campaign.brief.description || '',
    useLinkbyUtmTag: campaign.details.utm.enabled || false,
    customUtmTags:
      campaign.details.utm.source !== linkbyDefaultsUTM.source ||
      campaign.details.utm.medium !== linkbyDefaultsUTM.medium ||
      campaign.details.utm.campaign !== linkbyDefaultsUTM.campaign ||
      campaign.details.utm.content !== linkbyDefaultsUTM.content,
    source: campaign.details.utm.source || linkbyDefaultsUTM.source,
    medium: campaign.details.utm.medium || linkbyDefaultsUTM.medium,
    campaignName: campaign.details.utm.campaign || linkbyDefaultsUTM.campaign,
    content: campaign.details.utm.content || linkbyDefaultsUTM.content,
    allowDeeplinking: campaign.details.allowDeeplinking || false,
    affiliatePlatform: campaign.details.affiliatePlatform?.enabled || false,
    affiliatePlatformName:
      mapAffiliatePlatforms(campaign.details.affiliatePlatform?.platform) || '',
    sid:
      campaign.details.affiliatePlatform?.settings?.subid || '{publisherName}',
  })

  const form = useForm<FormValues>({
    defaultValues: loadDetails(campaign),
  })

  const handleInputChange = (e: { target: any }) => {
    const { name, value, type, checked } = e.target
    const val = type === 'checkbox' ? checked : value
    form.setValue(name, val)
  }

  const onSuccess = () => {
    toast({
      title: 'Campaign updated successfully',
      color: 'success',
      duration: 5000,
    })
    handleOpenChange(false)
    form.clearErrors()
    onClose()
  }

  const onError = async (error: any) => {
    try {
      if ((error?.data?.errors ?? []).length) {
        error.data.errors.map((x: any) => {
          form.setError(x.field, { message: x.message })
        })
      }
    } catch (e) {
      toast({
        title:
          'Failed to update campaign. Please contact tech support if issue persists.',
        color: 'destructive',
      })
      handleOpenChange(false)
    }
  }

  const handleOpenChange = (open: boolean) => {
    if (open) {
      const details = loadDetails(campaign)
      Object.keys(details).forEach((key) => {
        form.setValue(key as keyof FormValues, details[key as keyof FormValues])
      })
    }
    setIsOpen(open)

    if (!open) {
      onClose() // Emit onClose event when dialog is closed
      form.reset(loadDetails(campaign))
    }
  }

  const mapToCampaign = (formData: FormValues): CampaignUpdateRequest => {
    return {
      campaignName: formData.name?.trim() || '',
      campaignBrand: formData.brand?.trim() || '',
      referenceURL: formData.referenceUrl?.trim()?.startsWith('http')
        ? formData.referenceUrl?.trim()
        : `https://${formData.referenceUrl?.trim()}`,
      description: formData.description?.trim() || '',
      utm: !!formData.useLinkbyUtmTag || false,
      customUTMTags: {
        source: formData.customUtmTags
          ? formData.source?.trim()
          : linkbyDefaultsUTM.source,
        medium: formData.customUtmTags
          ? formData.medium?.trim()
          : linkbyDefaultsUTM.medium,
        campaign: formData.customUtmTags
          ? formData.campaignName?.trim()
          : linkbyDefaultsUTM.campaign,
        content: formData.customUtmTags
          ? formData.content?.trim()
          : linkbyDefaultsUTM.content,
      },
      allowDeeplinking: !!formData.allowDeeplinking || false,
      affiliatePlatformDetails: formData.affiliatePlatform
        ? {
            platform: (
              formData.affiliatePlatformName?.trim() || ''
            ).toLowerCase(),
            SID: formData.sid?.trim() || '',
          }
        : undefined,
    }
  }

  const { mutate: updateCampaignMutation, isPending } =
    useUpdateCampaignMutation({
      campaign,
      onSuccess,
      onError,
    })

  const validate = (campaign: any): boolean => {
    const result = CampaignUpdateSchema.safeParse(campaign)

    const formFieldMapping: { [key: string]: string } = {
      campaignName: 'name',
      campaignBrand: 'brand',
      referenceURL: 'referenceUrl',
      description: 'description',
      utm: 'useLinkbyUtmTag',
      'customUTMTags.source': 'source',
      'customUTMTags.medium': 'medium',
      'customUTMTags.campaign': 'campaignName',
      'customUTMTags.content': 'content',
      'affiliatePlatformDetails.platform': 'affiliatePlatformName',
      'affiliatePlatformDetails.SID': 'sid',
    }

    if (!result.success) {
      result.error.issues.forEach((issue) => {
        const path = issue.path.join('.')
        form.setError(formFieldMapping[path] as any, {
          message:
            formFieldMapping[path] === 'affiliatePlatformName'
              ? 'Please select one of the supported affiliate platforms'
              : issue.message,
        })
      })
    }

    return result.success
  }

  const triggerUpdateCampaign = () => {
    const values = mapToCampaign(form.getValues())
    if (validate(values)) {
      updateCampaignMutation(values)
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-h-[90vh] overflow-y-auto overflow-x-hidden sm:max-w-[425px]">
        <DialogTitle className="flex items-center justify-between">
          Edit Campaign #{campaign.id}
        </DialogTitle>
        <Form {...form}>
          <form className="space-y-4">
            <div>
              <FormLabel
                htmlFor="name"
                className={cn('', {
                  'text-destructive': form.formState.errors.name,
                })}>
                Campaign name
              </FormLabel>
              <FormField
                name="name"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        id="name"
                        className="text-md text-primary"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              {form.formState.errors?.name ? (
                <FormMessage className="px-0 pt-0 text-left">
                  {form.formState.errors.name?.type == 'required' ? (
                    <span>Campaign Name is required</span>
                  ) : (
                    <span>{form.formState.errors.name.message}</span>
                  )}
                </FormMessage>
              ) : null}
            </div>
            <div>
              <FormLabel
                htmlFor="brand"
                className={cn('', {
                  'text-destructive': form.formState.errors.brand,
                })}>
                Campaign brand
              </FormLabel>
              <FormField
                name="brand"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        id="brand"
                        className="text-md text-primary"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              {form.formState.errors?.brand ? (
                <FormMessage className="px-0 pt-0 text-left">
                  {form.formState.errors.brand?.type == 'required' ? (
                    <span>Campaign Brand is required</span>
                  ) : (
                    <span>{form.formState.errors.brand.message}</span>
                  )}
                </FormMessage>
              ) : null}
            </div>
            <div>
              <FormLabel
                htmlFor="referenceUrl"
                className={cn('', {
                  'text-destructive': form.formState.errors.referenceUrl,
                })}>
                Reference URL
              </FormLabel>
              <FormField
                name="referenceUrl"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        id="referenceUrl"
                        className="text-md text-primary"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              {form.formState.errors?.referenceUrl ? (
                <FormMessage className="px-0 pt-0 text-left">
                  {form.getValues('referenceUrl')?.trim()?.length === 0 ? (
                    <span>Campaign URL is required</span>
                  ) : (
                    <span>{form.formState.errors.referenceUrl.message}</span>
                  )}
                </FormMessage>
              ) : null}
            </div>
            <div>
              <FormLabel
                htmlFor="description"
                className={cn('', {
                  'text-destructive': form.formState.errors.description,
                })}>
                Description
              </FormLabel>
              <FormField
                name="description"
                rules={{ required: true }}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        id="description"
                        rows={4}
                        className="text-md text-primary"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              {form.formState.errors?.description ? (
                <FormMessage className="px-0 pt-0 text-left">
                  {form.formState.errors.description?.type == 'required' ? (
                    <span>Campaign Description is required</span>
                  ) : (
                    <span>{form.formState.errors.description.message}</span>
                  )}
                </FormMessage>
              ) : null}
            </div>
            <div className="flex items-center space-x-2">
              <FormLabel
                htmlFor="useLinkbyUtmTag"
                className={cn('', {
                  'text-destructive': form.formState.errors.useLinkbyUtmTag,
                })}>
                Use Linkby UTM Tag
              </FormLabel>
              <FormField
                name="useLinkbyUtmTag"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Switch
                        id="useLinkbyUtmTag"
                        checked={form.getValues('useLinkbyUtmTag')}
                        onCheckedChange={(checked) =>
                          handleInputChange({
                            target: {
                              name: 'useLinkbyUtmTag',
                              type: 'checkbox',
                              checked,
                            },
                          })
                        }
                        size="sm"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              {form.formState.errors?.useLinkbyUtmTag ? (
                <FormMessage className="px-0 pt-0 text-left">
                  {<span>{form.formState.errors.useLinkbyUtmTag.message}</span>}
                </FormMessage>
              ) : null}
            </div>
            {form.watch('useLinkbyUtmTag') && (
              <div className="space-y-2 pl-4">
                <div className="flex items-center space-x-2">
                  <FormField
                    name="customUtmTags"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Switch
                            id="customUtmTags"
                            checked={form.getValues('customUtmTags')}
                            onCheckedChange={(checked) =>
                              handleInputChange({
                                target: {
                                  name: 'customUtmTags',
                                  type: 'checkbox',
                                  checked,
                                },
                              })
                            }
                            size="sm"
                            {...field}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  {form.formState.errors?.customUtmTags ? (
                    <FormMessage className="px-0 pt-0 text-left">
                      {
                        <span>
                          {form.formState.errors.customUtmTags.message}
                        </span>
                      }
                    </FormMessage>
                  ) : null}
                  <FormLabel
                    htmlFor="customUtmTags"
                    className={cn('text-xs', {
                      'text-destructive': form.formState.errors.customUtmTags,
                    })}>
                    Custom UTM Tags
                  </FormLabel>
                </div>
                {form.watch('customUtmTags') && (
                  <div className="grid grid-cols-[auto,1fr] gap-2">
                    <FormLabel
                      htmlFor="source"
                      className={cn('self-center text-primary', {
                        'text-destructive': form.formState.errors.source,
                      })}>
                      Source
                    </FormLabel>
                    <FormField
                      name="source"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              type="text"
                              id="source"
                              className="text-md rounded-md border px-3 py-1 text-primary"
                              {...field}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    {form.formState.errors?.source ? (
                      <FormMessage className="px-0 pt-0 text-left">
                        {<span>{form.formState.errors.source.message}</span>}
                      </FormMessage>
                    ) : null}
                    <FormLabel
                      htmlFor="medium"
                      className={cn('self-center text-primary', {
                        'text-destructive': form.formState.errors.medium,
                      })}>
                      Medium
                    </FormLabel>
                    <FormField
                      name="medium"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              type="text"
                              id="medium"
                              className="text-md rounded-md border px-3 py-1 text-primary"
                              {...field}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    {form.formState.errors?.medium ? (
                      <FormMessage className="px-0 pt-0 text-left">
                        {<span>{form.formState.errors.medium.message}</span>}
                      </FormMessage>
                    ) : null}
                    <FormLabel
                      htmlFor="campaignName"
                      className={cn('self-center text-primary', {
                        'text-destructive': form.formState.errors.campaignName,
                      })}>
                      Campaign
                    </FormLabel>
                    <FormField
                      name="campaignName"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              type="text"
                              id="campaignName"
                              className="text-md rounded-md border px-3 py-1 text-primary"
                              {...field}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    {form.formState.errors?.campaignName ? (
                      <FormMessage className="px-0 pt-0 text-left">
                        {
                          <span>
                            {form.formState.errors.campaignName.message}
                          </span>
                        }
                      </FormMessage>
                    ) : null}
                    <FormLabel
                      htmlFor="content"
                      className={cn('self-center text-primary', {
                        'text-destructive': form.formState.errors.content,
                      })}>
                      Content
                    </FormLabel>
                    <FormField
                      name="content"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              type="text"
                              id="content"
                              className="text-md rounded-md border px-3 py-1 text-primary"
                              {...field}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    {form.formState.errors?.content ? (
                      <FormMessage className="px-0 pt-0 text-left">
                        {<span>{form.formState.errors.content.message}</span>}
                      </FormMessage>
                    ) : null}
                  </div>
                )}
              </div>
            )}
            <div className="flex items-center space-x-2">
              <FormLabel
                htmlFor="allowDeeplinking"
                className={cn('', {
                  'text-destructive': form.formState.errors.allowDeeplinking,
                })}>
                Allow deeplinking
              </FormLabel>
              <FormField
                name="allowDeeplinking"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Switch
                        id="allowDeeplinking"
                        checked={form.getValues('allowDeeplinking')}
                        onCheckedChange={(checked) =>
                          handleInputChange({
                            target: {
                              name: 'allowDeeplinking',
                              type: 'checkbox',
                              checked,
                            },
                          })
                        }
                        size="sm"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              {form.formState.errors?.allowDeeplinking ? (
                <FormMessage className="px-0 pt-0 text-left">
                  {
                    <span>
                      {form.formState.errors.allowDeeplinking.message}
                    </span>
                  }
                </FormMessage>
              ) : null}
            </div>

            {Object.keys(affiliatePlatforms).length > 0 && (
              <>
                <div className="flex items-center space-x-2">
                  <FormLabel
                    htmlFor="affiliatePlatform"
                    className={cn('', {
                      'text-destructive':
                        form.formState.errors.affiliatePlatform,
                    })}>
                    Affiliate platform
                  </FormLabel>
                  <FormField
                    name="affiliatePlatform"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Switch
                            id="affiliatePlatform"
                            checked={form.getValues('affiliatePlatform')}
                            onCheckedChange={(checked) =>
                              handleInputChange({
                                target: {
                                  name: 'affiliatePlatform',
                                  type: 'checkbox',
                                  checked,
                                },
                              })
                            }
                            size="sm"
                            {...field}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  {form.formState.errors?.affiliatePlatform ? (
                    <FormMessage className="px-0 pt-0 text-left">
                      {
                        <span>
                          {form.formState.errors.affiliatePlatform.message}
                        </span>
                      }
                    </FormMessage>
                  ) : null}
                </div>

                {form.watch('affiliatePlatform') && (
                  <div className="flex items-start space-x-4">
                    <FormField
                      name="affiliatePlatformName"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Select
                              onValueChange={(value) =>
                                handleInputChange({
                                  target: {
                                    name: 'affiliatePlatformName',
                                    value,
                                  },
                                })
                              }
                              value={field.value}
                              name={field.name}>
                              <SelectTrigger
                                color="primary"
                                className={cn(
                                  'w-[150px] border-gray-300 rounded-md',
                                  {
                                    'bg-destructive':
                                      form.formState.errors
                                        .affiliatePlatformName,
                                    'border-destructive':
                                      form.formState.errors
                                        .affiliatePlatformName,
                                  },
                                )}>
                                <SelectValue placeholder="Select affiliate">
                                  <span className="capitalize">
                                    {form.getValues('affiliatePlatformName')}
                                  </span>
                                </SelectValue>
                              </SelectTrigger>
                              <SelectContent className="z-[999999] border-none">
                                {Object.keys(affiliatePlatforms).map(
                                  (platform: string) => (
                                    <SelectItem
                                      className="capitalize"
                                      key={platform}
                                      value={platform}>
                                      {platform}
                                    </SelectItem>
                                  ),
                                )}
                              </SelectContent>
                            </Select>
                          </FormControl>
                          {form.formState.errors?.affiliatePlatformName ? (
                            <FormMessage className="px-0 pt-0 text-left">
                              <span>
                                {
                                  form.formState.errors.affiliatePlatformName
                                    .message
                                }
                              </span>
                            </FormMessage>
                          ) : null}
                        </FormItem>
                      )}
                    />

                    <div className="flex items-center space-x-2">
                      <FormLabel
                        htmlFor="sid"
                        className={cn('', {
                          'text-destructive': form.formState.errors.sid,
                        })}>
                        SID
                      </FormLabel>
                      <FormField
                        name="sid"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input
                                className="text-md w-[200px] text-primary"
                                {...field}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      {form.formState.errors?.sid ? (
                        <FormMessage className="flex px-0 pt-0 text-left">
                          {
                            <span>
                              {form.formState.errors.sid.message as any}
                            </span>
                          }
                        </FormMessage>
                      ) : null}
                    </div>
                  </div>
                )}
              </>
            )}
          </form>
        </Form>
        <DialogFooter>
          <div className="flex-end mt-2 flex space-x-2">
            <DialogClose asChild>
              <Button size="sm" color="default" variant="raised">
                Cancel
              </Button>
            </DialogClose>
            <Button
              color="action"
              variant="raised"
              size="sm"
              onClick={triggerUpdateCampaign}
              disabled={isPending}>
              {isPending && (
                <Icon
                  icon="mdi:loading"
                  className="mr-2 size-4 animate-spin cursor-pointer"
                />
              )}
              Update
            </Button>{' '}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditCampaignDialog
