'use client'

import { ReactNode } from 'react'
import { useParams } from 'next/navigation'
import env from '@/env'

import { CampaignStatusEnum } from '@/enums/campaign.enum'
import { Campaign } from '@/schemas/campaign.schema'

import { parseLocationHash } from '@/lib/utils'

import ActionsDropdown, {
  ActionDropdownItem,
  ComponentDropdownItem,
  LinkDropdownItem,
} from '../common/actions-dropdown'
import ApproveCampaignDialog from './dialogs/approve-campaign-dialog'
import EditCampaignDialog from './dialogs/edit-campaign-dialog'
import EndCampaignDialog from './dialogs/end-campaign-dialog'
import MoveCampaignBackToDraftDialog from './dialogs/move-campaign-back-to-draft-dialog'
import MoveCampaignBackToPendingDialog from './dialogs/move-campaign-back-to-pending-dialog'
import RejectCampaignDialog from './dialogs/reject-campaign-dialog'
import UpdateCampaignBudgetDialog from './dialogs/update-campaign-budget-dialog'
import UpdateCampaignPeriodDialog from './dialogs/update-campaign-period-dialog'

export type ActionButtonComponent = {
  campaign: Campaign
  children?: ReactNode
  preOpen?: boolean
  onClose?: () => void
}

const CampaignActionsButton = ({
  campaign,
  className,
  customItems,
}: {
  campaign: Campaign
  className?: string
  customItems?: ActionDropdownItem[]
}) => {
  const { campaignId } = useParams()
  const triggerAction = parseLocationHash(window.location.hash)?.triggerAction

  const isProduction = env.NEXT_PUBLIC_IS_PRODUCTION

  const isDetailsPage = !!campaignId

  const actionDialogMap = !isProduction
    ? {
        updateBudget: UpdateCampaignBudgetDialog,
        updateTimeframe: UpdateCampaignPeriodDialog,
        editCampaign: EditCampaignDialog,
      }
    : {
        editCampaign: EditCampaignDialog,
        updateTimeframe: UpdateCampaignPeriodDialog,
      }

  const UpdateCampaignPeriod = isDetailsPage
    ? new ComponentDropdownItem<ActionButtonComponent>({
        icon: 'mdi:calendar',
        label: 'Update Timeframe',
        group: 'action',
        component: UpdateCampaignPeriodDialog,
        componentProps: {
          campaign: campaign,
        },
      })
    : new LinkDropdownItem({
        icon: 'mdi:calendar',
        label: 'Update Timeframe',
        link: `/campaigns/${campaign.id}#triggerAction=updateTimeframe`,
        group: 'action',
      })

  const UpdateCampaignBudget = isDetailsPage
    ? new ComponentDropdownItem<ActionButtonComponent>({
        icon: 'mdi:dollar',
        label: 'Update Budget',
        component: UpdateCampaignBudgetDialog,
        group: 'action',
        componentProps: {
          campaign: campaign,
        },
      })
    : new LinkDropdownItem({
        icon: 'mdi:dollar',
        label: 'Update Budget',
        link: `/campaigns/${campaign.id}#triggerAction=updateBudget`,
        group: 'action',
      })

  const EditCampaign = new LinkDropdownItem({
    icon: 'mdi:pencil',
    label: 'Edit Campaign',
    link: `/campaigns/${campaign.id}#triggerAction=editCampaign`,
    group: 'action',
  })

  const MoveCampaignBackToDraft =
    new ComponentDropdownItem<ActionButtonComponent>({
      icon: 'fluent:drafts-20-filled',
      label: 'Move Back to Draft',
      component: MoveCampaignBackToDraftDialog,
      componentProps: {
        campaign: campaign,
      },
      group: 'action',
    })

  const MoveCampaignBackToPending =
    new ComponentDropdownItem<ActionButtonComponent>({
      icon: 'material-symbols:pending-actions-rounded',
      label: `Move Back to Pending`,
      component: MoveCampaignBackToPendingDialog,
      componentProps: {
        campaign: campaign,
      },
      group: 'action',
    })

  const actions: Record<CampaignStatusEnum, ActionDropdownItem[]> = {
    [CampaignStatusEnum.Draft]: [
      ...(!isDetailsPage ? [EditCampaign] : []),
      UpdateCampaignBudget,
      UpdateCampaignPeriod,
    ],
    [CampaignStatusEnum.Finished]: [UpdateCampaignPeriod, UpdateCampaignBudget],
    [CampaignStatusEnum.Rejected]: [],
    [CampaignStatusEnum.Pending]: [
      ...(!isDetailsPage ? [EditCampaign] : []),
      ...(campaign.publisherPitchId || campaign.isPicked
        ? []
        : [MoveCampaignBackToDraft]),
      UpdateCampaignBudget,
      UpdateCampaignPeriod,
      new ComponentDropdownItem<ActionButtonComponent>({
        icon: 'mdi:approve',
        label: 'Approve Campaign',
        component: ApproveCampaignDialog,
        componentProps: {
          campaign: campaign,
        },
        group: 'action',
      }),
      new ComponentDropdownItem<ActionButtonComponent>({
        icon: 'mdi:cancel-circle',
        label: 'Reject Campaign',
        component: RejectCampaignDialog,
        componentProps: {
          campaign: campaign,
        },
        group: 'action',
      }),
    ],
    [CampaignStatusEnum.Active]: [
      ...(!isDetailsPage ? [EditCampaign] : []),
      ...(isDetailsPage &&
      Number(campaign?.allocatedBudget ?? 0) === 0 &&
      !(campaign.publisherPitchId || campaign.isPicked)
        ? [MoveCampaignBackToPending]
        : []),
      ...(campaign.publisherPitchId || campaign.isPicked
        ? []
        : [MoveCampaignBackToDraft]),
      UpdateCampaignBudget,
      UpdateCampaignPeriod,
      new ComponentDropdownItem<ActionButtonComponent>({
        icon: 'solar:power-bold',
        label: 'End Campaign',
        component: EndCampaignDialog,
        componentProps: {
          campaign: campaign,
        },
        group: 'action',
      }),
    ],
  }
  // Use this block to hide any actions that are not allowed in production
  const campaignActions = isProduction
    ? (actions?.[campaign?.status] ?? []).filter(
        (action) =>
          // Disabling update budget, move back to pending for production
          ![''].includes(action.label),
      )
    : (actions?.[campaign?.status] ?? [])

  const handlePreOpen = () => {
    if (triggerAction) {
      const component = {
        c: actionDialogMap[
          triggerAction as keyof typeof actionDialogMap
        ] as React.FC<ActionButtonComponent>,
      }

      if (component && component.c) {
        return (
          <component.c
            campaign={campaign}
            preOpen={true}
            onClose={() =>
              window.history.replaceState(null, '', window.location.pathname)
            }
          />
        )
      }
    }
    return null
  }

  return (
    <div>
      <ActionsDropdown
        className={className}
        items={[...campaignActions, ...(customItems || [])]}
      />
      {handlePreOpen()}
    </div>
  )
}

export default CampaignActionsButton
