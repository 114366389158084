'use client'

import { ReactNode } from 'react'
import Link from 'next/link'
import { Icon } from '@iconify/react'

import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

type Group = 'action' | 'connection'
export abstract class ActionDropdownItem {
  icon!: string
  label!: string
  group?: Group
  type!: 'component' | 'link' | 'action'

  constructor(icon: string, label: string, group?: Group) {
    this.icon = icon
    this.label = label
    this.group = group
  }

  abstract render(index: number): ReactNode
}

export class ComponentDropdownItem<
  Props extends {},
> extends ActionDropdownItem {
  component!: React.FC<Props>
  componentProps!: Props

  constructor({
    icon,
    label,
    component,
    componentProps,
    group,
  }: {
    icon: string
    label: string
    component: React.FC<Props>
    componentProps: Props
    group?: Group
  }) {
    super(icon, label, group)
    this.component = component
    this.componentProps = componentProps
    this.type = 'component'
  }

  render(index: number) {
    const action = {
      component: this.component,
      props: this.componentProps || {},
    }
    if (!action.component) {
      return null
    }
    const Component = action.component as React.FC<Props>
    return (
      <Component key={index} {...(action.props as any)}>
        <DropdownMenuItem
          onSelect={(e) => {
            e.preventDefault()
          }}
          className="my-1 flex cursor-pointer items-center gap-2 px-3 text-[.85rem] font-medium capitalize text-default-600 dark:hover:bg-background">
          <Icon icon={this.icon} className="size-4" />
          {this.label}
        </DropdownMenuItem>
      </Component>
    )
  }
}

export class LinkDropdownItem extends ActionDropdownItem {
  link?: string

  constructor({
    icon,
    label,
    group,
    link,
  }: {
    icon: string
    label: string
    group?: Group
    link?: string
  }) {
    super(icon, label, group)
    this.link = link
    this.type = 'link'
  }

  render(index: number) {
    return (
      <Link key={index} href={this.link || '#'}>
        <DropdownMenuItem
          onSelect={(e) => {
            e.preventDefault()
          }}
          className="my-1 flex cursor-pointer items-center gap-2 px-3 text-[.85rem] font-medium capitalize text-default-600 dark:hover:bg-background">
          <Icon icon={this.icon} className="size-4" />
          {this.label}
        </DropdownMenuItem>
      </Link>
    )
  }
}

export class CustomActionDropdownItem extends ActionDropdownItem {
  customAction?: () => Promise<void> | void

  constructor({
    icon,
    label,
    group,
    customAction,
  }: {
    icon: string
    label: string
    group?: Group
    customAction?: () => Promise<void> | void
  }) {
    super(icon, label, group)
    this.customAction = customAction
    this.type = 'action'
  }

  render(index: number) {
    return (
      <DropdownMenuItem
        key={index}
        onSelect={async (e) => {
          e.preventDefault()
          if (this.customAction) {
            await this.customAction()
          }
        }}
        className="my-1 flex cursor-pointer items-center gap-2 px-3 text-[.85rem] font-medium capitalize text-default-600 dark:hover:bg-background">
        <Icon icon={this.icon} className="size-4" />
        {this.label}
      </DropdownMenuItem>
    )
  }
}

const ActionsDropdown = ({
  className,
  items,
}: {
  className?: string
  items: ActionDropdownItem[]
}) => {
  const actionItems = items?.filter((action) => action.group === 'action') || []
  const connectionItems =
    items?.filter((action) => action.group === 'connection') || []
  const ungroupedItems =
    items?.filter((action) => action.group === undefined) || []

  const allGroups = [
    { label: 'Actions', items: actionItems },
    { label: 'Connections', items: connectionItems },
    { label: null, items: ungroupedItems },
  ].filter((x) => x.items.length)

  if (!allGroups || !allGroups?.length) {
    return <></>
  }

  const renderGroups = (): React.ReactNode => {
    const groups: React.ReactNode[] = []
    allGroups.forEach((group, index) => {
      groups.push(
        <DropdownMenuGroup key={group.label}>
          {group.label ? (
            <DropdownMenuLabel className="my-1 px-3 text-xs text-default-600">
              {group.label}
            </DropdownMenuLabel>
          ) : null}
          {group.items.map((action, index) => action.render(index))}
        </DropdownMenuGroup>,
      )
      if (allGroups[index + 1] != undefined) {
        groups.push(<DropdownMenuSeparator></DropdownMenuSeparator>)
      }
    })
    return groups
  }

  if (!items || !items?.length) {
    return <></>
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="cursor-pointer">
        <Button
          size="icon"
          color="default"
          variant="raised"
          className={className}>
          <Icon icon="heroicons-solid:dots-horizontal" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="p-0" align="end">
        {renderGroups()}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default ActionsDropdown
