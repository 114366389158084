export const isUsingDefaultUTMTags = (campaign: any) => {
  const utm = campaign?.details?.utm
  return (
    (utm?.source && utm?.source !== 'linkby') ||
    (utm?.medium && utm?.medium !== 'cpc') ||
    (utm?.campaign && utm?.campaign !== '{campaignName}') ||
    (utm?.content && utm?.content !== '{publisherName}')
  )
}

/**
 * Calculate the minimum budget after adjusting for threshold clicks.
 *
 * This method ensures that the budget is not set below the amount already spent plus
 * an additional buffer for threshold clicks.
 *
 * @param {number} budget - The current budget.
 * @param {number} spent - The amount already spent.
 * @param {number} thresholdClicks - The number of threshold clicks to account for.
 * @param {number} cpc - The cost per click.
 * @returns {number} - The minimum budget after adjusting for threshold clicks.
 */
export const getMinimumBudgetAfterAdjustingThresholdClicks = (
  budget: number,
  spent: number,
  thresholdClicks: number,
  cpc: number,
): number => {
  return Math.min(
    Number(budget),
    Number(spent) + Number(thresholdClicks) * Number(cpc),
  )
}
