import { z } from 'zod'

import { isUrl } from '@/lib/utils'

export const affiliatePlatforms = z.enum([
  'awin',
  'rakuten',
  'shareasale',
  'partnerize',
  'cf',
] as const)

export const CampaignUpdateSchema = z.object({
  campaignName: z.string().min(1, 'Campaign name is required'),
  campaignBrand: z.string().min(1, 'Campaign brand is required'),
  referenceURL: z.string().refine((value) => {
    try {
      return isUrl(value.startsWith('http') ? value : `https://${value}`)
    } catch {
      return false
    }
  }, 'Invalid URL format'),
  description: z.string().min(1, 'Description is required'),
  utm: z.boolean(),
  customUTMTags: z.object({
    source: z.string().optional(),
    medium: z.string().optional(),
    campaign: z.string().optional(),
    content: z.string().optional(),
  }),
  allowDeeplinking: z.boolean(),
  affiliatePlatformDetails: z
    .object({
      platform: affiliatePlatforms,
      SID: z.string(),
    })
    .optional(),
})
