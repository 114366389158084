import { keepPreviousData, useQuery } from '@tanstack/react-query'

import {
  getCampaignCountryStates,
  getCampaignFeatureArticles,
  getCampaignInvoices,
  getCampaignPerformance,
  getCampaignPerformanceInvalid,
  getCampaignProductCards,
  getCampaignPublisherBrands,
  getCampaigns,
  getCampaignTimeline,
} from '.'

export const useCampaignsQuery = (filters: any) => {
  return useQuery({
    queryKey: ['campaigns', filters],
    queryFn: () => getCampaigns(filters),
    placeholderData: keepPreviousData,
  })
}

export const useCampaignPublisherBrandsQuery = (id: string) => {
  return useQuery({
    queryKey: ['campaign_publisher_brands', id],
    queryFn: () => getCampaignPublisherBrands(id),
    placeholderData: keepPreviousData,
  })
}

export const useCampaignInvoicesQuery = (id: string) =>
  useQuery({
    queryKey: ['campaign_invoices', id],
    queryFn: () => getCampaignInvoices(id),
    placeholderData: keepPreviousData,
  })

export const useCampaignPerformanceQuery = (
  campaignId: string,
  brandId?: string,
  type?: string,
) => {
  return useQuery({
    queryKey: ['campaign_performance', { campaignId, brandId, type }],
    queryFn: () => getCampaignPerformance(campaignId, brandId),
    placeholderData: keepPreviousData,
  })
}

export const useCampaignPerformanceInvalidQuery = (
  campaignId: string,
  brandId?: string,
) => {
  return useQuery({
    queryKey: ['campaign_performance_invalid', { campaignId, brandId }],
    queryFn: () => getCampaignPerformanceInvalid(campaignId, brandId),
    placeholderData: keepPreviousData,
  })
}

export const useCampaignProductCardsQuery = (id: string) => {
  return useQuery({
    queryKey: ['campaign_pubfeed_product_cards', id],
    queryFn: () => getCampaignProductCards(id),
    placeholderData: keepPreviousData,
  })
}

export const useCampaignFeatureArticlesQuery = (id: string) => {
  return useQuery({
    queryKey: ['campaign_pubfeed_feature_articles', id],
    queryFn: () => getCampaignFeatureArticles(id),
    placeholderData: keepPreviousData,
  })
}

export const useCampaignTimelineQuery = (id: string) => {
  return useQuery({
    queryKey: ['campaign_timeline', id],
    queryFn: () => getCampaignTimeline(id),
    placeholderData: keepPreviousData,
  })
}

export const useCampaignCountryStates = (countryCode: string) => {
  return useQuery({
    queryKey: ['campaign_country_states', countryCode],
    queryFn: () => getCampaignCountryStates(countryCode),
    placeholderData: keepPreviousData,
  })
}
